<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessFour :api="api"/>
        <v-row>
            <v-col align="start" justify="start"
            class="pt-5 pb-0">
                <v-btn 
                color="primary"
                @click="()=>{
                    $router.go(-1);
                }">
                    <v-icon>
                        mdi-arrow-left-bold
                    </v-icon>
                </v-btn>  
            </v-col>
        </v-row>
        <v-row
        class="">
            <v-col
            align="center"
            justify="center"
            class="mx-auto mt-5 pt-5"
            cols="6"
            lg="6">
                <v-toolbar
                    color="secondary"
                    flat 
                    class="text-h5 white--text py-4"
                    height="auto">
                        New Original Invoice
                </v-toolbar>
                <v-stepper  
                    v-model="currentStep" non-linear> 
                    <v-row
                        class="pt-2">
                        <v-col
                            justify="center"
                            align="center"
                            class="mx-auto mt-3"
                            cols="9"
                            lg="9">
                            <v-stepper-header
                                style="box-shadow: none;">
                                <v-stepper-step
                                    step="1">
                                    Upload invoice
                                </v-stepper-step>
                                <v-divider></v-divider>
                                <v-stepper-step
                                    step="2">
                                    Fill in invoice detail
                                </v-stepper-step>
                            </v-stepper-header>
                        </v-col>
                    </v-row>
                    <v-stepper-items
                        class="pb-0">
                        <v-stepper-content
                            step="1">
                                    <v-card class="px-3 "
                                    style="box-shadow: none;">
                                        <div
                                            class="d-flex justify-space-between mb-2">
                                            <span
                                                class="text-h6 font-weight-bold">
                                                Upload  invoice
                                            </span>
                                            <!-- <div class=" d-flex justify-end mb-3">
                                            <v-btn color="primary" @click="()=>{
                                                this.currentStep = this.currentStep+1;
                                            }" :loading="api.isLoading">
                                                Next
                                            </v-btn> -->
                                            <!-- </div> -->
                                        </div>
                                        <div class="">
                                            <v-file-input
                                            :rules="rules"
                                            accept=".pdf"
                                            color="primary"
                                            v-model="files"
                                            outlined
                                            prepend-icon=""
                                            label="Please choose the files"
                                            @change="generatePDFView(files)"
                                            counter
                                            :show-size="1000">
                                                <template v-slot:selection="{index,text}">
                                                    <v-chip
                                                    v-if="index<2"
                                                    color="deep-purple accent-4"
                                                    dark
                                                    label
                                                    small>
                                                        {{ text }}
                                                    </v-chip>
                                                    <span
                                                    v-else-if="index==2" class="text-overline grey--text text--darken-3">
                                                        +{{ files.length-2 }} Files(s)
                                                    </span>
                                                </template>
                                            </v-file-input>
                                        </div>
                                        <v-row>
                                            <v-col
                                                class="mx-3 mb-6 pb-6">
                                                <div id="drop_zone" class="" @dragover="dragOverHandler()" :style="{borderColor:isHoverColor}" style="border:2px dashed grey;width:100%;height:200px" @drop="dropHandler" @dragover.prevent @dragenter.prevent @dragleave="onDragLeave" >
                                                    <v-row class="justify-center " style="align-items: center;" >
                                                        <v-col cols="12" class="text-center pt-5 mt-5">
                                                            <v-icon :style="{color: isHoverColor}"  size="50px">
                                                                bi-upload
                                                            </v-icon>   
                                                            <p :style="{color:isHoverColor}" class="d-flex justify-center align-center">
                                                                Drag and the invoice pdf file here.
                                                            </p>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                    
                                            </v-col>
                                        </v-row>
                                        <!-- <v-row
                                            v-if="this.$router.history.current.params.attachment=='Refund'">
                                            <v-col
                                                class="mt-6">
                                                <v-textarea
                                                    outlined
                                                    auto-grow
                                                    label = "Please write your remark ( Optional )"
                                                    dense
                                                    width="auto"
                                                    height="auto"
                                                    v-model="remark">
                    
                                                </v-textarea>
                                            </v-col>
                                        </v-row> -->
                                        <div>
                                        </div>
                                    </v-card>
                                <!-- </v-col>
                            </v-row> -->
                        </v-stepper-content>
                        <v-stepper-content
                            step="2">
                            <PageCreateXeroInvoice
                            :invoice_no="invoiceData.invoice_no"
                            :client_no="invoiceData.client_no"
                            :subtotal="invoiceData.subtotal"
                            :amount_due="invoiceData.amount_due"
                            :tax_amount="invoiceData.tax_amount"
                            :total="invoiceData.total"
                            :issued_at="invoiceData.issued_at"
                            :fileInvoice="files"
                            @returnBack="()=>{
                                this.currentStep = this.currentStep-1;
                            }"/>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-col>
            <v-col
                cols="6"
                lg="6"
                md="12"
                class="mx-auto mt-5 pt">
                <iframe
                    
                    v-if="pdfUrl!=null"
                    :src="pdfUrl"
                    height="100%"
                    width="100%">
                </iframe>
                <AError :api="this.api"/>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import AConfirmation from '../../components/common/AConfirmation.vue';
import AError from '../../components/common/AError.vue';
import ASuccessFour from '../../components/common/ASuccessFour.vue';
import PageCreateXeroInvoice from '../../views/attachment/PageCreateXeroInvoice.vue';
import pdfjs from 'pdfjs-dist/webpack';
export default {
    components:{
    AConfirmation,
    AError,
    ASuccessFour,
    PageCreateXeroInvoice,
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        isHoverColor:'grey',
        currentStep:1,
        isPending:false,
        rules:[
            value=>value[0].size < 10000000 || 'File size should be less than 10 MB!',
            value=>value.length<=3 || 'Total files that can be uploaded should be less than 3 files',
        ],
        invoiceData:{
            invoice_no:null,
            client_no:null,
            amount_due:null,
            subtotal:null,
            total:null,
            tax_amount:null,
            issued_at:null,
        },
        files:[],
        remark:null,
        api:{
            isSuccesful:false,
            isError:false,
            isLoading:false,
            error:null,
            url:null,
            success:null,
        },
        pdfUrl:null,
        companyId:null,
    }),
    created() {
        this.api.callbackReset = () =>
        {
            this.api.isLoading = true;
            this.api.isError = false;
            this.api.isSuccesful = false;
        };
        this.api.callbackError = (e) =>
        {
            this.api.isError = true;
            this.api.isLoading = false;
            this.api.isSuccesful = false;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) =>
        {
            if(resp.class=='uploadAttachmentWithEmail'){
                let typeOfAttachment = this.$router.history.current.params.attachment;
                typeOfAttachment = typeOfAttachment.charAt(0).toLowerCase() + typeOfAttachment.slice(1);
                this.api.success = "Succesfully upload the "+typeOfAttachment;
                this.api.isSuccesful = true;
            }
            this.api.isError = false;
            this.api.isLoading = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        dropHandler(ev){
            ev.preventDefault();

            if(ev.dataTransfer.items){
                let allowedFilesFormat = ["image/png","image/jpeg","application/pdf"];

                [...ev.dataTransfer.items].forEach((item) => {
                    // if(item.kind === "file"){
                    // }
                    const file = item.getAsFile();
                    // let type = file.type;
                    if(!allowedFilesFormat.includes(file.type)){
                        this.api.isError = true;
                        this.api.error = "Cannot upload"+ file.name+" , invalid format";
                        return 1;
                    }
                    this.generatePDFView(file);
                    this.files.push(file);
                });
            }
            this.isHoverColor="grey"
        },
        // async extractPdfContent(file) {
        //     let arrayBuffer = await file.arrayBuffer(); // Convert file to ArrayBuffer
        //     await this.extractPdfText(arrayBuffer); // Extract text conten
        //     let pdfDoc = await pdfjs.getDocument({data:arrayBuffer}).promise();
        //     let page = await pdfDoc.getPage(1);
        //     let textData = await page.getTextContent();
        //     let pdfContent = textData.items.map((item)=>item.str).join(" ");
        //     return pdfContent;
        // },
        extractContent(content) {

            let regexInvoiceNo = /YGC-\d{6}/g;
            let regexClientNo = /Client No\.\s*(\d+)/g;
            let regexAmountDue = /Amount Due\s*([\d,]+\.\d{2})/g;
            let regexSubtotal = /Subtotal \(Excluding Service Tax\)\s*([\d,]+\.\d{2})/g;
            let regexTotal = /Total \(Inclusive of Service Tax\)\s*([\d,]+\.\d{2})/g;
            let regexTax = /Service Tax\s+\d+%\s*(\d+\.\d{2})/g;
            let regexDate = /Date\s*(\d{1,2}\s+\w+\s+\d{4})/;

            let invoiceNo = content.match(regexInvoiceNo);
            let clientNo = content.match(regexClientNo);
            let amountDue = content.match(regexAmountDue);
            let subTotal = content.match(regexSubtotal);
            let total = content.match(regexTotal);
            let tax = content.match(regexTax);
            let issuedDate = content.match(regexDate);
            if(invoiceNo) {
                this.invoiceData.invoice_no = invoiceNo[0];
            }
            if(clientNo){
                clientNo =  clientNo.map(match=>{
                    let foundNo = match.match(/\d+/);
                    return foundNo?foundNo[0]:null;
                })
                this.invoiceData.client_no = clientNo[0];
            }
            if(amountDue){
                amountDue = amountDue.map(match=>{
                    let foundAmount = match.match(/[\d,]+\.\d{2}/);
                    return foundAmount?foundAmount[0]:null;
                });
                this.invoiceData.amount_due = amountDue[0];

            }
            if(subTotal) {
                subTotal = subTotal.map(match=> {
                    let foundSubtotal = match.match(/[\d,]+\.\d{2}/);
                    return foundSubtotal?foundSubtotal[0]:null;
                });
                this.invoiceData.subtotal = subTotal[0];
            }
            if(total) {
                total = total.map(match=>{
                    let foundTotal = match.match(/[\d,]+\.\d{2}/);
                    return foundTotal?foundTotal[0]:null;
                });
                this.invoiceData.total = total[0];
            }
            if(tax) {
                tax = tax.map(match=>{
                    let foundTax = match.match(/[\d,]+\.\d{2}/);
                    return foundTax?foundTax[0]:null;
    
                });
                this.invoiceData.tax_amount = tax[0];
            }
            if(issuedDate) {
               
                this.invoiceData.issued_at = this.$moment(issuedDate[1],"DD MMMM YYYY").format("YYYY-MM-DD");
            }
    

        },
        generatePDFView(file) {
            this.pdfUrl = null;
            this.api.isError = false;
            this.api.error = "";

            if( file && file.type === 'application/pdf') {
                this.pdfUrl = URL.createObjectURL(file);
                file.arrayBuffer()
                .then((arrayBuffer)=>{
                    return pdfjs.getDocument({data:arrayBuffer}).promise;
                })
                .then((pdfDoc)=>{
                    return pdfDoc.getPage(1);
                })
                .then((page)=>{
                    return page.getTextContent(); 
                })
                .then((textContent)=>{
                    let text = textContent.items.map((item)=>item.str).join("");
                    this.extractContent(text);
                })
                .catch((error)=>{
                    alert(error);
                })
                // this.extractPdfContent(file);
                this.currentStep = this.currentStep +1;
            }
            else {
                this.api.isError = true;
                this.api.error = "To view the invoice, please upload the pdf file"
            }
            // else {
            //     alert("Please upload valid pdf file");
            // }

        },
        dragOverHandler(){
            this.isHoverColor = "blue";
        },
        onDragLeave(){
            this.isHoverColor="grey";
        },
        fetch(){
            this.generatePDFView(this.files);

        },
        redirectBack(){
            this.$router.go(-1);
        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            let createAttachmentApi;
            createAttachmentApi = this.create();
            this.$api.fetch(createAttachmentApi);
        },
        cancelSubmit(){
            this.isPending = false;
        },
        create(){
            let tempApi = this.$_.clone(this.api);
            tempApi.methods = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+'/attachment/email/company/'+this.companyId;
            let formData = new FormData();
            if(this.files!=null){
                this.files.forEach(element => {
                    formData.append('attachments[]',element);
                });
                formData.append('attachmentType',this.$router.history.current.params.attachment);
                formData.append('userEmail',this.$store.getters.getEmail);
                if(this.remark!=null) {
                    formData.append('remark',this.remark);
                }
            }
            // formData.append('formType','Payment');
            // formData.append('remark',this.remark);
            // formData.append("userEmail",this.$store.getters.getEmail);
            // formData.append("remark",(this.remark==null)?this.$store.getters.getFname+" "+this.$store.getters.getLname+" is sending a new receipt":this.remark);
            // formData.append('status','Pending');
            tempApi.params = formData;
            return tempApi;
        },
    }
}
</script>
