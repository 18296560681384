<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessWithoutReload  @close="()=>{
            this.$router.go(-1);
        }" :api="this.api"/>
       
        <v-row>
            <v-col
                lg="12"
                cols="12"
                md="12">
                <v-card
                    flat
                    class="px-3">
                    <v-row
                        class="">
                        <v-col
                            class="pt-0 mt-0"
                            cols="12"
                            lg="12"
                            md="12">
                            <div
                                class="d-flex justify-start">
                                <v-btn 
                                    class="mb-4"
                                    color="primary"
                                    @click="()=>{
                                        this.$emit('returnBack')
                                    }">
                                        <!-- <v-icon>
                                            mdi-arrow-left-bold
                                        </v-icon> -->
                                        Go back
                                    </v-btn>  
                            </div>
                            <div
                                class="d-flex justify-start mb-2">
                                <span
                                    class="text-h6 font-weight-bold">
                                    Invoice details
                                </span>
                            </div>
                            <div
                                class="d-flex justify-start">
                                <v-autocomplete
                                    v-model="data.company_id"
                                    dense 
                                    item-value="company_id"
                                    item-text="company_name"
                                    :items="companyOptions"
                                    :loading="isLoadingCompany"
                                    outlined
                                    required 
                                    label="Company (required)">
                                    <template
                                        v-slot:item="{item}">
                                        <span>
                                            {{ item.company_id }} - {{ item.company_name }}
                                        </span>
                                    </template>
                                </v-autocomplete>
                            </div>
                            <div
                                class="d-flex justify-start">
                                <v-text-field
                                    label="Invoice no"
                                    dense 
                                    outlined 
                                    v-model="invoice_no">
                                </v-text-field>
                            </div>
                            <div
                                class="d-flex justify-start">
                                <v-text-field
                                    label="Client no"
                                    dense 
                                    outlined
                                    v-model="client_no">
                                </v-text-field>
                            </div>
                            <ComponentDateModal
                                label="Issued date"
                                :data="issued_at"
                                @updateDateData="(data)=>{
                                    this.issued_at = data;
                                }"
                            />
                            <div    
                                class="d-flex justify-start">
                                <v-textarea
                                    label="Description (required)"
                                    dense 
                                    outlined
                                    v-model="data.description">
                                </v-textarea>
                            </div>
                            <div
                                class="d-flex justify-start">
                                <v-text-field
                                    label="Subtotal ( RM )"
                                    dense 
                                    outlined
                                    v-model="subtotal">
                                </v-text-field>
                            </div>
                            <div
                                class="d-flex justify-start">
                                <v-text-field
                                    dense 
                                    outlined 
                                    v-model="tax_amount"
                                    label="Tax Amount ( RM )">
                                </v-text-field>
                            </div>
                            <div
                                class="d-flex justify-start">
                                <v-text-field
                                    dense 
                                    outlined
                                    label="Total ( RM )"
                                    v-model=total>
                                </v-text-field>
                            </div>
                            <div
                                class="d-flex justify-start">
                                <v-text-field
                                    dense 
                                    outlined 
                                    label="Amount due ( RM )"
                                    v-model="amount_due">
                                </v-text-field>
                            </div>
                            <div
                                class="d-flex justify-end">
                                <v-btn
                                    color="primary"
                                    text
                                    @click="()=>{
                                        $router.go(-1)
                                    }">
                                        Cancel
                                </v-btn>
                                <v-btn
                                    @click="validateInput"
                                    color="primary"
                                    :loading="api.isLoading">
                                    Confirm
                                </v-btn>
                            </div>
                            <div
                                class="d-flex justify-end">
                                <AError
                                    :api="this.api"/>

                            </div>
                        </v-col>
                    </v-row>

                </v-card>
                
            </v-col>
        </v-row>

        <!-- <v-row>
            <v-col
                style="min-height:700px"
                lg="6"
                cols="12"
                md="12"
                sm="12">
            </v-col>
            <v-col
                style="min-height:700px"
                lg="6"
                sm="12">
            </v-col>
        </v-row> -->
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ComponentDateModal from '../../components/date/ComponentDateModal.vue';
import ASuccessWithoutReload from '../../components/common/ASuccessWithoutReload.vue';
// import jsPDF from 'jspdf';
export default {
    components:{
        ComponentDateModal,
        ASuccessWithoutReload,
},
    computed: mapState({
    //
}),
    props:[
        'invoice_no',
        'client_no',
        'subtotal',
        'amount_due',
        'tax_amount',
        'total',
        'issued_at',
        'fileInvoice'
    ],
    data () {
        return{
        data:{
            'company_id':null,
            'user_name':null,
            'email':null,
            'address':"",
            'invoice_no':null,
            'invoice_link':null,
            'client_no':null,
            'subtotal':null,
            'tax_amount':null,
            'amount_due':null,
            'total':null,
            'issued_at':null,
            'description':null,
            'pic_email':null,
            'is_assigned_pic':false,
            'is_set_default_address':false,
        },
        // cc_email:[],
        // isshowAssignedPIC:false,
        // isShowSetAsAddress:false,
        // pdf:null,
        isPending:false,
        isLoadingCompany:true,
        // isLoadingGeneratePdf:false,
        // currentPICEmail:null,
        // currentAddress:null,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            },
        companyOptions:null,

    };
    },
    
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
            this.isLoadingSendEmail=false;
            this.isLoadingXero = false;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class === 'getCompanies') {
                this.companyOptions = resp.data;
                this.isLoadingCompany = false;
            }
            if(resp.class==='manulStoreXeroInvoice') {
                this.api.isSuccesful = true;
                this.api.success = "Succesfully create a new invoice"
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            this.$api.fetch(this.fetchCompanies());
            if(typeof this.$route.query.company_id != undefined) {
                this.data.company_id = this.$route.query.company_id;
            }
        },
       
        // BOC : Create a new function to build the api and return it to the fetch function
        fetchCompanies() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API?.replace("v1","v3")+'/companies';
            return tempApi;
        },
        // EOC
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            this.$api.fetch(this.createXeroInvoice());
        },
        createXeroInvoice() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/xero/invoices/"+this.data.company_id;
            this.data.invoice_no = this.invoice_no;
            this.data.client_no = this.client_no;
            this.data.subtotal = this.subtotal;
            this.data.tax_amount = this.tax_amount;
            this.data.amount_due = this.amount_due;
            this.data.total = this.total;
            this.data.issued_at = this.issued_at;
            let formData = new FormData;
            formData.append('attachment',this.fileInvoice);
            Object.entries(this.data).forEach(([key,value])=>{
                formData.append(key,value);
            });
            tempApi.params = formData;
            return tempApi;
        },
        closeSuccessModal() {
            this.api.isSuccesful = false;
            this.$router.go(-1);
        },
        convertBase64ToPdf(base64) {
            this.pdf = null;
            const binaryString = atob(base64);

            // Convert binary string to array buffer
            const byteArray = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
                byteArray[i] = binaryString.charCodeAt(i);
            }

            const blob = new Blob([byteArray], { type: 'application/pdf' });
            this.pdf = this.$_.clone(blob);
        

            const url = URL.createObjectURL(blob);
            this.pdfUrl = url;
            this.isLoadingGeneratePdf = false;
        },
        handleChange() {

            if(Object.keys(this.cc_email).length>3) {
                this.api.isError = true;
                this.api.error ="You can only cc to max 3 emails";
                this.cc_email.pop();
                return 1;
            }
            this.api.isError = false;
        },
        removeItem(index) {
            this.cc_email.splice(index,1);
        },
        checkPICEmail() {
            this.isshowAssignedPIC = false;
            this.data.is_assigned_pic=false;
            if(this.data.pic_email != this.currentPICEmail) {
                //if user want to change the pic email, allow user to tick the checkbox to set if want to use this email as current pic email
                this.isshowAssignedPIC = true;
                this.data.is_assigned_pic = false
            }
        },
        checkAddress() {
            this.isShowSetAsAddress = false;
            this.data.is_set_default_address = false;
            if(this.data.address != this.currentAddress && this.data.address !="") {
                this.isShowSetAsAddress = true;
                this.data.is_set_default_address = false;
            }
        },
    }
}
</script>