var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('AConfirmation',{attrs:{"isShow":_vm.isPending},on:{"cancel":_vm.cancelSubmit,"confirm":_vm.submit}}),_c('ASuccessFour',{attrs:{"api":_vm.api}}),_c('v-row',[_c('v-col',{staticClass:"pt-5 pb-0",attrs:{"align":"start","justify":"start"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function (){
                _vm.$router.go(-1);
            }}},[_c('v-icon',[_vm._v(" mdi-arrow-left-bold ")])],1)],1)],1),_c('v-row',{},[_c('v-col',{staticClass:"mx-auto mt-5 pt-5",attrs:{"align":"center","justify":"center","cols":"6","lg":"6"}},[_c('v-toolbar',{staticClass:"text-h5 white--text py-4",attrs:{"color":"secondary","flat":"","height":"auto"}},[_vm._v(" New Original Invoice ")]),_c('v-stepper',{attrs:{"non-linear":""},model:{value:(_vm.currentStep),callback:function ($$v) {_vm.currentStep=$$v},expression:"currentStep"}},[_c('v-row',{staticClass:"pt-2"},[_c('v-col',{staticClass:"mx-auto mt-3",attrs:{"justify":"center","align":"center","cols":"9","lg":"9"}},[_c('v-stepper-header',{staticStyle:{"box-shadow":"none"}},[_c('v-stepper-step',{attrs:{"step":"1"}},[_vm._v(" Upload invoice ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"2"}},[_vm._v(" Fill in invoice detail ")])],1)],1)],1),_c('v-stepper-items',{staticClass:"pb-0"},[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-card',{staticClass:"px-3 ",staticStyle:{"box-shadow":"none"}},[_c('div',{staticClass:"d-flex justify-space-between mb-2"},[_c('span',{staticClass:"text-h6 font-weight-bold"},[_vm._v(" Upload invoice ")])]),_c('div',{},[_c('v-file-input',{attrs:{"rules":_vm.rules,"accept":".pdf","color":"primary","outlined":"","prepend-icon":"","label":"Please choose the files","counter":"","show-size":1000},on:{"change":function($event){return _vm.generatePDFView(_vm.files)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var index = ref.index;
            var text = ref.text;
return [(index<2)?_c('v-chip',{attrs:{"color":"deep-purple accent-4","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):(index==2)?_c('span',{staticClass:"text-overline grey--text text--darken-3"},[_vm._v(" +"+_vm._s(_vm.files.length-2)+" Files(s) ")]):_vm._e()]}}]),model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1),_c('v-row',[_c('v-col',{staticClass:"mx-3 mb-6 pb-6"},[_c('div',{staticStyle:{"border":"2px dashed grey","width":"100%","height":"200px"},style:({borderColor:_vm.isHoverColor}),attrs:{"id":"drop_zone"},on:{"dragover":[function($event){return _vm.dragOverHandler()},function($event){$event.preventDefault();}],"drop":_vm.dropHandler,"dragenter":function($event){$event.preventDefault();},"dragleave":_vm.onDragLeave}},[_c('v-row',{staticClass:"justify-center ",staticStyle:{"align-items":"center"}},[_c('v-col',{staticClass:"text-center pt-5 mt-5",attrs:{"cols":"12"}},[_c('v-icon',{style:({color: _vm.isHoverColor}),attrs:{"size":"50px"}},[_vm._v(" bi-upload ")]),_c('p',{staticClass:"d-flex justify-center align-center",style:({color:_vm.isHoverColor})},[_vm._v(" Drag and the invoice pdf file here. ")])],1)],1)],1)])],1),_c('div')],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('PageCreateXeroInvoice',{attrs:{"invoice_no":_vm.invoiceData.invoice_no,"client_no":_vm.invoiceData.client_no,"subtotal":_vm.invoiceData.subtotal,"amount_due":_vm.invoiceData.amount_due,"tax_amount":_vm.invoiceData.tax_amount,"total":_vm.invoiceData.total,"issued_at":_vm.invoiceData.issued_at,"fileInvoice":_vm.files},on:{"returnBack":function (){
                            this$1.currentStep = this$1.currentStep-1;
                        }}})],1)],1)],1)],1),_c('v-col',{staticClass:"mx-auto mt-5 pt",attrs:{"cols":"6","lg":"6","md":"12"}},[(_vm.pdfUrl!=null)?_c('iframe',{attrs:{"src":_vm.pdfUrl,"height":"100%","width":"100%"}}):_vm._e(),_c('AError',{attrs:{"api":this.api}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }